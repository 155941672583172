import { Checkbox, Button } from '@vitalsource/vstui';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { useKey } from '../../BrandProvider';
import useUser from '../../utils/useUser';

const Styled = {
  Checkbox: styled(Checkbox)`
    & > div:first-child {
      padding: 0;
    }
  `,
};

const Terms = () => {
  const intl = useIntl();

  // If the brand does not override these urls, we fall back to the translation
  const { eulaAccepted } = useUser();

  const skipTOS = useKey('skipTOS', false);
  const termsURL = useKey(
    'Terms.url',
    intl.formatMessage({ id: 'locations.zendesk-tou', defaultMessage: '#' }),
  );
  const privacyURL = useKey(
    'Privacy.url',
    intl.formatMessage({
      id: 'locations.zendesk-privacy',
      defaultMessage: '#',
    }),
  );

  if (!skipTOS && !eulaAccepted) {
    return (
      <Styled.Checkbox
        fullWidth
        id="tos"
        name="user[eula-accepted]"
        label={
          <FormattedMessage
            id="registration.accept-tos-fill-links"
            values={{
              eulaURL: (
                <Button
                  href={termsURL}
                  target="_blank"
                  inline
                  data-test-id="terms-link"
                >
                  <FormattedMessage id="terms.terms" />
                </Button>
              ),
              privacyURL: (
                <Button
                  href={privacyURL}
                  target="_blank"
                  inline
                  data-test-id="privacy-link"
                >
                  <FormattedMessage id="terms.privacy-policy" />
                </Button>
              ),
            }}
          />
        }
        required
        data-test-id="terms-checkbox"
      />
    );
  } else {
    return null;
  }
};

export default Terms;
