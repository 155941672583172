export const setParamToURL = (
  pathToUse: string,
  key: string,
  value: string,
): string => {
  try {
    const url = new URL(pathToUse);
    url.searchParams.set(key, value);
    return url.toString();
  } catch (e) {
    return pathToUse;
  }
};

// Gets the full URL with all query and anchor params, but swaps out the path.
// This is used for all form submissions to persist query params
export const relativePathWithParams = (relativePath: string): string => {
  const matched = window.location.pathname.match(
    /^(\/u\/[0-9a-zA-Z]*)(\/?.*)/i,
  );

  const base = matched ? matched[1] : '';
  const fetchUrl = new URL(window.location.href);
  fetchUrl.pathname = base + relativePath;
  return fetchUrl.toString();
};
