import { ReactChild } from 'react';
import styled, { css } from 'styled-components';
import Logo from '../Logo';
import PoweredBy from '../PoweredBy';
import FooterButtons from '../FooterButtons';
import { PageTitle } from '../../utils/PageTitle';
import useMediaQuery, { MediaQueries } from '../../utils/useMediaQuery';
import CookieBanner from '../cookie-banner';
import bgClouds from './bg-clouds.svg';
import bgLamp from './bg-lamp.svg';
import bgMoon from './bg-moon.svg';
import bgSmClouds from './bg-sm-clouds.svg';
import bgTableBooksGreen from './bg-table-books-green.svg';
import bgTableBooks from './bg-table-books.svg';
import { useFooterButtons } from '../../utils/useFooterButtons';
import { isBookshelf, isStore } from '../../utils/AppContext';

const Styled = {
  PageViewFrame: styled.div`
    position: absolute;
    width: 100%;
    bottom: 0px;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    ${(props: { allFooterButtonsDisabled: boolean }): string =>
      !props.allFooterButtonsDisabled
        ? MediaQueries.smallStyled`
      bottom:40px;
      `
        : ''}
  `,
  Background: styled.div<{
    showBackgroundGraphic: boolean;
    backgroundGraphicType: string;
  }>`
    width: 1878px;
    max-width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    min-height: 100vh;
    ${({ showBackgroundGraphic }) =>
      showBackgroundGraphic
        ? css`
            background-position: ${({ backgroundGraphicType }) => {
              if (backgroundGraphicType === 'green')
                return `top 30px left 45%, top 54px right 37px,
                  bottom -125px left 81px`;
              return `top 60px left 40%, top 0px right 20px,
                bottom -70px left -40px`;
            }};

            background-size: ${({ backgroundGraphicType }) => {
              if (backgroundGraphicType === 'green')
                return '360px 178px, 232px 102px, 522px 520px';
              return '258px 180px, 93px 480px, 675px 425px';
            }};
            background-repeat: no-repeat;

            background-image: ${({ backgroundGraphicType }) => {
              if (backgroundGraphicType === 'green')
                return `url('${bgClouds}'), url('${bgSmClouds}'),
                url('${bgTableBooksGreen}')`;
              return `url('${bgMoon}'), url('${bgLamp}'),
                url('${bgTableBooks}')`;
            }};
            ${MediaQueries.smallStyled`
          padding: 0;
          background: none;
      `}
          `
        : css`
            padding: 0;
            background: none;
          `}
  `,
  ParentContainer: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 100px 0 0 0;
    ${MediaQueries.smallStyled`
      padding: 0;
      width: auto;
      max-width: 100%;
  `}
  `,
  LeftContainer: styled.div<{
    pageLocation: string;
  }>`
    display: flex;
    flex: 1;
    padding: 0 15px 0 100px;
    margin: ${({ marginTop }) => (marginTop ? marginTop : '6%')} 0 0 0;
    height: 100%;
    align-items: flex-start;
  `,
  RightContainer: styled.div`
    display: flex;
    padding: 0 135px 150px 0;
    margin: 0;
    max-width: 100%;
    height: 100%;
    ${MediaQueries.smallStyled`
    padding: 0;
  `}
  `,
  Wrapper: styled.div`
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 55px 0 rgba(5, 30, 55, 0.2);
    width: 470px;
    max-width: 100%;
    padding: 36px 32px 20px 32px;
    height: 100%;
    ${MediaQueries.smallStyled`
    margin: auto;
    box-shadow: none;
    border-radius: revert;
  `}
  `,
};

// VST-62786: This is a temporary solution because chameleon is setup in a way that falls
// back to empty as its default value. We can't risk bookshelf brands accidentally
// getting the vitalsource logo unexpectedly.
const getDefaultLogo = (): string => {
  if (isStore()) {
    return '/img/logo-vitalsource-store.png';
  }
  if (isBookshelf()) {
    return '/img/logo-bookshelf-dark.svg';
  }
  return '/img/logo-bookshelf-dark.svg';
};

const PageViewContainer = ({
  children,
  leftContainerContent,
  rightContainerContent,
  PageViewContainerEnabled = true,
  showBackgroundGraphic = false,
  showLogo,
  logoPath,
  poweredByEnabled,
  poweredByLogoPath,
  pageTitle,
  backgroundGraphicType,
  marginTop,
}: {
  children?: ReactChild;
  leftContainerContent: ReactChild | null;
  rightContainerContent: ReactChild | null;
  PageViewContainerEnabled?: boolean;
  showBackgroundGraphic?: boolean;
  showLogo?: boolean;
  logoPath?: string;
  poweredByEnabled?: boolean;
  poweredByLogoPath?: string;
  pageTitle?: string;
  backgroundGraphicType?: string;
  marginTop?: string;
}) => {
  const isSmallScreen = useMediaQuery(MediaQueries.small);
  const { allFooterButtonsDisabled } = useFooterButtons();

  if (PageViewContainerEnabled) {
    return (
      <>
        <PageTitle title={pageTitle} />
        <Styled.PageViewFrame
          allFooterButtonsDisabled={allFooterButtonsDisabled}
        >
          <Styled.Background
            showBackgroundGraphic={showBackgroundGraphic}
            backgroundGraphicType={backgroundGraphicType}
          >
            <Styled.ParentContainer>
              {!isSmallScreen && (
                <Styled.LeftContainer marginTop={marginTop}>
                  {leftContainerContent}
                </Styled.LeftContainer>
              )}
              <Styled.RightContainer>
                <Styled.Wrapper>
                  {showLogo && <Logo logoPath={logoPath || getDefaultLogo()} />}
                  {rightContainerContent}
                  {poweredByEnabled && (
                    <PoweredBy poweredByLogoPath={poweredByLogoPath} />
                  )}
                </Styled.Wrapper>
              </Styled.RightContainer>
              {children}
            </Styled.ParentContainer>
          </Styled.Background>
        </Styled.PageViewFrame>
        <FooterButtons />
        <CookieBanner />
      </>
    );
  }
  return null;
};

export default PageViewContainer;
