import ErrorConnector from './connectors/ErrorConnector';
import CookieError from './pages/error/cookie';
import useThirdPartyCookiesEnabled from './utils/use3rdPartyCookies';

export default function ErrorProvider({ children }) {
  const thirdPartyCookiesEnabled = useThirdPartyCookiesEnabled();

  if (window._alohomora?.errorPage) {
    return <ErrorConnector />;
  } else if (!thirdPartyCookiesEnabled) {
    return <CookieError />;
  } else {
    return children;
  }
}
